<template>
  <div class="participants-page">
    <div class="boxxqq_div22">
      <treedata @projectId-child="projectIdClick"></treedata>
    </div>
    <div class="boxxqq_div11">
      <div class="boxxqq_div12">
        <el-button type="primary" icon="el-icon-plus" @click="Configureroles" :disabled="disabledflag">添加成员</el-button>
        <!-- <el-button type="primary" icon="el-icon-plus" @click="addmember">新增成员</el-button> -->
      </div>
      <div class="content" style="height: 660px">
        <el-table
          :data="tableData"
          style="width: 100%"
          max-height="660"
          :header-cell-style="{
            color: '#333333',
            backgroundColor: '#FBFBFD',
          }"
          row-key="id"
          border
          lazy>
          <el-table-column
            prop="UserName"
            label="姓名"
            align="center"
            width="120">
          </el-table-column>
          <el-table-column
            prop="LoginName"
            label="登录名"
            align="center"
            width="120">
          </el-table-column>
          <el-table-column
            prop="PhoneNumber"
            label="电话"
            align="center"
            min-width="170">
          </el-table-column>
          <el-table-column
            prop="IdentityCard"
            label="身份证"
            align="center"
            min-width="190">
          </el-table-column>
          <el-table-column
            prop="RoleName"
            label="角色"
            align="center"
            width="180">
          </el-table-column>
           <el-table-column
            prop="RoleName"
            label="性别"
            align="center"
            width="80">
           <template slot-scope="scope">
                 <span>{{scope.row.Sex==1?"男":"女"}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="HospitalName"
            align="center"
            label="单位"
            min-width="180"
          >
          </el-table-column>
          <el-table-column
            prop="DeptName"
            align="center"
            label="科室"
            min-width="180"
          >
          </el-table-column>
          <el-table-column label="操作" align="center" width="180">
            <template slot-scope="scope">
              <el-link
                :underline="false"
                v-show="scope.row.Id !== -1&&disabledflag==false?true:false"
                style="font-size: 16px;color: #3978E7;margin-right: 17px"
                @click="OKedit(scope.$index, scope.row)"
                ><i class="el-icon-edit-outline"></i>编辑</el-link>
              <el-link
                :underline="false"
                v-show="scope.row.Id !== -1&&disabledflag==false?true:false"
                style="font-size: 16px;color: #3978E7"
                @click="handleDelete(scope.$index, scope.row)"><i class="el-icon-delete"></i>删除</el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="eachpage"
          layout="total,prev, pager, next, jumper"
          :total="PageCount">
        </el-pagination>
      </div>
    </div>
     <div class="dialog_add">
        <el-dialog
          :visible.sync="dialogVisible"
          v-dialogDrag
         >
          <div slot="title" class="header-title">
            <span class="title-name"></span>
            <span class="title-age">新增成员</span>
          </div>
          <div class="body">
            <div class="body-left">
              <el-form label-width="150px" :model="addForm" :rules="rules" ref="addForm">
                <el-form-item label="登录名:" required>
                  <el-input v-model="LoginName" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="密码:" required>
                  <el-input v-model="Password" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="姓名:" required>
                  <el-input v-model="UserName" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="手机号:" prop="PhoneNumber">
                  <el-input v-model="addForm.PhoneNumber" placeholder="请输入" style="width: 150px"></el-input>
                  <span style="color: #F56C6C">推荐填写,该项用于接收各类通知</span>
                </el-form-item>
                <el-form-item label="身份证:" prop="IdentityCard">
                  <el-input v-model="addForm.IdentityCard" placeholder="请输入" @blur="IdBlur"></el-input>
                </el-form-item>
                <el-form-item label="性别:">
                  <el-radio-group v-model="Sex">
                    <el-radio :label="1">男</el-radio>
                    <el-radio :label="0">女</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </div>
            <div class="body-right">
              <el-form label-width="150px" :model="addForm2" :rules="rules2">
                <el-form-item label="微信:">
                  <el-input v-model="WX" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="邮箱:" prop="EMail">
                  <el-input v-model="addForm2.EMail" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="职称:">
                  <el-select  placeholder="请选择" v-model="Title">
                    <el-option :label="item.Name" :value="item.Name" v-for="(item,index) in TitleArray" :key="index"></el-option>
                  </el-select>
                </el-form-item>
                 <el-form-item label="医院:" required>
                  <el-autocomplete
                  class="inline-input"
                  v-model="HospitalName"
                  value-key="HospitalName"
                  :fetch-suggestions="querySearch"
                  placeholder="请输入内容"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
                </el-form-item>
                <el-form-item label="科室:" required>
                  <el-select v-model="DepartmentId" placeholder="请选择" filterable>
                    <el-option
                      :label="item.DeptName"
                      :value="item.Id"
                      v-for="(item, index) in Department"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="角色:" required>
                  <el-select v-model="RoleId" placeholder="请选择">
                    <el-option
                      :label="item.RoleName"
                      :value="item.Id"
                      v-for="(item, index) in option"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div class="btn">
            <el-button  @click="dialogVisible = false">取消</el-button>
            <el-button @click="OKmember('addForm')" :loading="userLoading">确定</el-button>
          </div>
        </el-dialog>
      </div>
      <div class="dialog_add">
        <el-dialog
          :visible.sync="handleEditDialog"
          v-dialogDrag
        >
          <div slot="title" class="header-title">
            <span class="title-name"></span>
            <span class="title-age">编辑</span>
          </div>
          <div class="body">
            <div class="body-left">
              <el-form label-width="150px">
                <el-form-item label="登录名:">
                  <el-input v-model="editForm.LoginName"></el-input>
                </el-form-item>
                <el-form-item label="密码:">
                  <el-input v-model="editForm.Password" disabled></el-input>
                </el-form-item>
                <el-form-item label="姓名:">
                  <el-input v-model="editForm.UserName"></el-input>
                </el-form-item>
                <el-form-item label="手机号:">
                  <el-input v-model="editForm.PhoneNumber"></el-input>
                </el-form-item>
                <el-form-item label="身份证:">
                  <el-input v-model="editForm.IdentityCard"></el-input>
                </el-form-item>
                <el-form-item label="性别:">
                  <el-radio-group v-model="editForm.Sex">
                    <el-radio :label="1">男</el-radio>
                    <el-radio :label="0">女</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </div>
            <div class="body-right">
              <el-form label-width="150px">
                <el-form-item label="微信:">
                  <el-input v-model="editForm.Wx"></el-input>
                </el-form-item>
                <el-form-item label="邮箱:">
                  <el-input v-model="editForm.EMail"></el-input>
                </el-form-item>
                <el-form-item label="职称:">
                  <el-select v-model="editForm.Title">
                    <el-option :label="item.Name" :value="item.Name" v-for="(item,index) in TitleArray" :key="index"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="医院:">
                  <el-input v-model="editForm.HospitalName" disabled></el-input>
                </el-form-item>
                <el-form-item label="科室:">
                  <el-input v-model="editForm.DeptName" disabled></el-input>
<!--                  <el-select v-model="DepartmentId" placeholder="请选择" filterable>-->
<!--                    <el-option-->
<!--                      :label="item.DeptName"-->
<!--                      :value="item.Id"-->
<!--                      v-for="(item, index) in Department"-->
<!--                      :key="index"-->
<!--                    ></el-option>-->
<!--                  </el-select>-->
                </el-form-item>
                <el-form-item label="角色:">
                  <el-select v-model="editForm.RoleId" placeholder="请选择">
                    <el-option
                      :label="item.RoleName"
                      :value="item.Id"
                      v-for="(item, index) in option"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div class="btn">
            <el-button  @click="handleEditDialog = false">取消</el-button>
            <el-button @click="submitEdit">确定</el-button>
          </div>
        </el-dialog>
      </div>
      <div class="handleDelDialog">
        <el-dialog
          :visible.sync=" handleDelDialog"
          width="510px"
          v-dialogDrag
        >
          <div slot="title" class="header-title">
            <span class="title-name"></span>
            <span class="title-age">提示</span>
          </div>
          <div class="body">
               <span>!</span><span>此操作将永久删除该成员角色,是否继续？</span>
          </div>
          <div class="btn">
            <el-button @click="handleDelDialog = false">取消</el-button>
            <el-button @click="OKdelete">确定</el-button>
          </div>
        </el-dialog>
    </div>
    <div class="ConfigurerolesDialog">
        <el-dialog
          :visible.sync="ConfigurerolesDialog"
          width="710px"
          v-dialogDrag
        >
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">配置角色</span>
      </div>
      <p style="margin-bottom: 6px">给已有成员指定角色:</p>
      <div style="display: flex;overflow: auto;max-height: 500px">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">姓名:</el-checkbox>
        <el-checkbox-group v-model="idlist" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="(data,index) in tableDataCheckAll" :label="data.Id" :key="index" >{{data.UserName}}</el-checkbox>
        </el-checkbox-group>
      </div>
      <el-form ref="form" label-width="120px">
        <el-form-item label="角色" required>
          <el-select v-model="RoleId" placeholder="请选择身份">
            <el-option :label="item.RoleName" :value="item.Id" v-for="(item,indexs) in list" :key="indexs"></el-option>
          </el-select>
          <el-button @click.prevent="addmember" class="add-user-btn">+新增成员</el-button>
        </el-form-item>
        <div class="btn">
          <el-button @click.prevent="ConfigurerolesDialog=false">取消</el-button>
          <el-button @click.prevent="Okaddrole">确定</el-button>
        </div>
      </el-form>
        </el-dialog>
    </div>
  </div>
</template>
<script>
import verification from "@/utils/verification";
import api from "../../../api/url"
import apis from "../../../api/Grantsandmilestones"
import {mapState} from "vuex";
import research from "@/api/research";
import treedata from "../../../components/treedata.vue"
export default {
  data(){
    return{
      userLoading: false,
      ConfigurerolesDialog:false,
      addForm2: {
        EMail: ""
      },
      addForm: {
        PhoneNumber:"",//手机号
        IdentityCard:"",//身份证
      },
      rules2: {
        EMail: [{ required: false,validator: verification.validateEmail, trigger: 'blur' }],
      },
      rules: {
        PhoneNumber: [
          { required: false, validator: verification.validatePhone, trigger: 'blur' }],
        IdentityCard: [{ required: false,validator: verification.validateIdCard, trigger: 'blur' }],
      },
      currentPage:1,
      PageCount:0,
      eachpage:10,
      editRoleId: "", // 编辑的角色Id
      editDeptName: "", //科室
      editHospitalName: "", //医院
      editForm: { // 编辑的信息
      },
      tableData:[],
      UserId:"",
      Id:"",
      HospitalName:"",//医院名字显示
      HospitalId:"",//医院id
      Title:"",//职称
      DepartmentId:"",//科室
      RoleId:"",//角色
      LoginName:"",//登录名
      Password:"",//密码
      UserName:"",//姓名
      PhoneNumber:"",//手机号
      IdentityCard:"",//身份证
      Sex:1,//性别
      WX:"",//微信
      QQ:"",//qq
      EMail:"",//邮箱
      option:[],//角色数组
      TitleArray:[],//职称数组
      Department:[],//科室数组
      handleEditDialog: false,//打开编辑成员弹框
      dialogVisible:false,//打开新增成员弹框
      handleDelDialog:false,//打开删除成员弹框
      list:[],
      tableDataCheckAll:[],
      checkAll:false,
      idlist:[],
      isIndeterminate:true
    }
  },
  watch: {
  },
  components:{
    treedata,
  },
  computed: mapState(["projectId","disabledflag"]),
  created(){
    this.jurisdictionlist()
    this.quan()
  },
  methods:{
      quan(){
      let parame={
        topicId:this.projectId,
        menu:"projectupdate"
      }
      apis.judgeData(parame).then(res=>{
        console.log(res,"%%%%%%%%%%%");
        if(res.data.Status!=1){
          this.$store.commit("midshow",true)
        }
      })
      },
      Okaddrole(){//确定配置角色
        if(this.idlist.length == 0) {
          this.$message.error("参与人员不能为空")
          return false
        }
        if(this.RoleId == "") {
          this.$message.error("添加参与人员,必须选择角色")
          return false
        }
        var UserIds=[]
        this.idlist.forEach(item=>{
          UserIds.push({
            TopicId:this.projectId,
            RoleId:this.RoleId,
            UserId:item,
          })
        })
        let parame={
           UserIds:UserIds
        }
        api.UserIdsData(parame).then(res=>{
          if(res.data.Status==1){
            this.$message.success(res.data.Message)
            this.ConfigurerolesDialog = false
            this.jurisdictionlist()
          }else{
             alert(res.data.Message)
          }
           console.log(res);
        })
      },
      handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.tableDataCheckAll.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.tableDataCheckAll.length;
      console.log(value)
      console.log(this.idlist)
      },
      handleCheckAllChange(val) {
      var list = JSON.parse(JSON.stringify(this.tableDataCheckAll))
      list = list.map(item=>{
        return item.Id
      })
      this.idlist = val ? list : [];
      console.log(this.idlist)
      this.isIndeterminate = false;
      },
      Configureroles(){
          this.idlist = []
          this.ConfigurerolesDialog=true
          research.allrole().then(res=>{
            this.list=res.data.Entity
            console.log(res,"1111");
          })
          let useid = this.projectId
          api.TopicUserData(useid).then(res=>{
            this.tableDataCheckAll = res.data.ExtraData
            console.log(res,"222");
          })
      },
      projectIdClick(data) {
      //树型结构传递过来的课题id
      this.$store.commit("SaveId", data);
      this.jurisdictionlist()
      console.log(data);
    },
    handleCurrentChange(val){
      this.currentPage=val
      this.jurisdictionlist()
      console.log(`当前页: ${val}`);
    },
    addmember(){//打开新增成员弹框
      this.LoginName = ""
      this.Password = ""
      this.UserName = ""
      this.addForm.PhoneNumber =""
      this.addForm.IdentityCard = ""
      this.NickName =""
      this.Sex = 1
      this.WX = ""
      this.QQ = ""
      this.addForm2.EMail = ""
      this.Title = ""
      this.HospitalId = ""
      this.DepartmentId =""
      this.RoleId = ""
      this.HospitalName = ""
       this.dialogVisible=true
       this.titlelist()
       this.allrolelist()
    },
    titlelist(){//职称数组
        let parame="JobTitle"
        api.titleData(parame).then(res=>{
               if(res.data.Status==1){
                   this.TitleArray=res.data.Entity
               }else{
                  alert(res.data.Message)
               }
        })
    },
    IdBlur() {
      const regId = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[X])$)$/;
      if (!regId.test(this.addForm.IdentityCard)) {
        console.log('身份证不正确')
      } else {
        if (this.addForm.IdentityCard.substr(16, 1)% 2 == 1){
          this.Sex = 1
        } else {
          this.Sex = 0
        }
      }
    },
    OKmember(formName){//确定新增成员
      this.userLoading = true
      if (this.DepartmentId == "") {
        this.$message.error('请选择科室')
        this.userLoading = false
      }
      if (this.RoleId == "") {
        this.$message.error('请选择角色')
        this.userLoading = false
        return false
      }
      if (this.Password == "") {
        this.$message.error('请输入6到20位密码')
        this.userLoading = false
        return false
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
         console.log('通过')
          this.addUserInfo()
        } else {
          this.$message.error('请填写正确信息')
          this.userLoading = false
          return false;
        }
      });
    },
    addUserInfo() { // 验证通过的回调
      let parame={
        // projectId:0,
        projectId:this.projectId,
        LoginName:this.LoginName,
        Password:this.Password,
        UserName:this.UserName,
        PhoneNumber:this.addForm.PhoneNumber,
        IdentityCard:this.addForm.IdentityCard,
        NickName:this.NickName,
        Sex:this.Sex,
        WX:this.WX,
        QQ:"",
        EMail:this.addForm2.EMail,
        Title:this.Title,
        HospitalId:this.HospitalId,
        DepartmentId:this.DepartmentId,
        RoleId:0,
      }
      research.addprojectUser(parame).then(res=>{
        console.log(res);
        if(res.data.Status==1){
          this.userLoading = false
          let obj={
            TopicId:this.projectId,
            RoleId:this.RoleId,
            UserId:res.data.NewID,
          }
          api.adduserData(obj).then(res=>{
            console.log(res);
            if(res.data.Status==1){
              this.userLoading = false
              this.dialogVisible=false
              this.$message.success(res.data.Message)
              let useid = window.sessionStorage.getItem("Id")
              research.allmember(useid).then(()=>{
                api.TopicUserData(this.projectId).then(res=>{
                  this.tableDataCheckAll = res.data.ExtraData
                })
              })
              this.jurisdictionlist()
            }else{
              this.userLoading = false
              alert(res.data.Message)
            }
          })
        }else{
          this.userLoading = false
          alert(res.data.Message)
        }
      })
    },
    querySearch(queryString, cb){//查询出医院
          console.log(queryString, cb);
          let parame={
              key:queryString
          }
          api.hospitalData(parame).then(res=>{
               console.log(res);
               if(res.data.Status==1){
                 cb(res.data.Entity)
               }else{
                 alert(res.data.Message)
               }
          })
    },
    handleSelect(item){//根据医院id查出科室
         this.HospitalId=item.Id
         let parame={
           HospitalId:item.Id
         }
       api.getDepartmentInfoData(parame).then(res=>{
         if(res.data.Status==1){
           this.Department=res.data.Entity
         }else{
           alert(res.data.Message)
         }
          console.log(res,"科室");
      })
    },
    jurisdictionlist(){//项目下的成员列表
      var obj = {
        Key: "",
        TopicId: this.projectId,
        PageIndex:this.currentPage-1,
        PageSize:this.eachpage,
      }
       research.getUserSList(obj).then(res=>{
                       console.log(res);
                     if(res.data.Status==1){
                      this.tableData=res.data.Entity
                      this.PageCount = res.data.TotalRowsCount
                    }else{
                      alert(res.data.Message)
                    }
       })
    },
    allrolelist(){//角色
      api.allroleData().then(res=>{
        if(res.data.Status==1){
          this.option=res.data.Entity
          }else{
            alert(res.data.Message)
        }
        console.log(res,"成员");
      })
    },
    async OKedit(i,row){//确定编辑人员
      console.log(row,"编辑成员");
      const res = await research.getUsersInfo(row.Id);
      console.log(res);
      if (res.data.Status == 1) {
        this.editForm = res.data.ExtraData;
        this.editRoleId = res.data.ExtraData.RoleId
      } else {
        this.$message.error("查询失败");
      }
      this.handleEditDialog=true
      this.allrolelist()
    },
    handleDelete(i,item){//删除这个项目下的成员
     this.handleDelDialog=true
     this.Id=item.Id
    },
    OKdelete(){//确定删除
        let parame={
          Id:this.Id
        }
       api.deleteuserData(parame).then(res=>{
             if(res.data.Status==1){
                  this.$message({
                    type: 'success',
                    message: '删除成功!'
                  });
               this.jurisdictionlist()
               this.handleDelDialog = false
             }else{
                 alert(res.data.Message)
             }
            console.log(res);
       })
    },
    submitEdit() {
      var parame={
        Id:this.editForm.Id,
        RoleId:this.editForm.RoleId,
        TopicId:this.projectId,
        UserId:this.editForm.UserId
      }
      if (this.editForm.RoleId !== this.editRoleId ) {
      api.personnelupData(parame).then(res=>{
        if(res.data.Status==1){
          console.log(res)
        }else{
          alert(res.data.Message)
        }
      })
      }
      this.editForm.Id = this.editForm.UserId
      research.personnelupData(this.editForm).then(res => {
        console.log(res,'编辑成功')
        if (res.data.Status ==1) {
          this.$message.success(res.data.Message)
          this.jurisdictionlist()
          this.handleEditDialog = false
        } else {
          this.$message.error(res.data.Message)
        }
      })
    }
  },
}
</script>
<style lang="scss" scoped>
@import "../../../styles/mixin.scss";
.participants-page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .boxxqq_div22{
    width: 240px;
    height: 94%;
    float: left;
    margin-top: 2%;
    margin-right: 30px;
    background: #fff;
    border-radius: 10px;
  }
  .boxxqq_div11{
    width: 83%;
    //height: 820px;
    height: 94%;
    //margin-left: 2%;
    margin-top: 2%;
    background: #FFFFFF;
    opacity: 1;
    padding: 1% 1% 0 1%;
    border-radius: 10px;
    box-sizing: border-box;
    float: left;
    .boxxqq_div12{
      width: 100%;
      margin-bottom: 10px;
      /deep/.el-button {
        @include add-size($font_size_16);
      }
    }
  }
}
.dialog_add {
  /deep/ .el-dialog {
    width: 1200px;
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;
      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
          margin-bottom: 5px;
        }
        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }
    .body {
      height: 470px;
      .body-left {
        float: left;
        .el-form {
          .el-form-item {
            .el-form-item__label {
              @include add-size($font_size_16);
              color: #666666;
            }
            .el-input {
              @include add-size($font_size_16);
              width: 390px;
            }
          }
        }
      }
      .body-right {
        float: left;
        .el-form {
          .el-form-item {
            .el-form-item__label {
              @include add-size($font_size_16);
              color: #666666;
            }
            .el-input {
              @include add-size($font_size_16);
              width: 390px;
            }
            .el-icon-circle-plus-outline {
              width: 30px;
              display: inline-block;
              font-size: 30px;
              vertical-align: middle;
            }
            .pspan {
              display: inline-block;
              width: 360px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              vertical-align: middle;

              .spanname {
                @include add-size($font_size_16);
                margin-left: 6px;
              }
            }
            .block {
              .el-date-editor--daterange.el-input,
              .el-date-editor--daterange.el-input__inner,
              .el-date-editor--timerange.el-input,
              .el-date-editor--timerange.el-input__inner {
                width: 390px;
              }
            }
            .el-radio__label{
              @include add-size($font_size_16);
            }
          }
        }
      }
    }
    .btn {
      margin-bottom: 14px;
      text-align: center;
      button:nth-child(1) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #3388ff;
        margin-right: 20px;
      }
      button:nth-child(2) {
        width: 180px;
        border: 1px solid #3388ff;
        background: #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #fff;
      }
    }
  }
}
.dialog_edit {
  /deep/.el-dialog {
    width: 398px;
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;
      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
          margin-bottom: 5px;
        }
        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }
    .el-form {
      .el-form-item {
        .el-form-item__label {
          @include add-size($font_size_16);
          color: #666666;
        }
        .el-input__inner {
          background: #F4F7F9;
        }
        .el-select {
          width: 300px;
          @include add-size($font_size_16);
        }
      }
    }
    .btn {
      text-align: center;
      button:nth-child(1) {
        width: 120px;
        background: #ffffff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #3388ff;
        margin-right: 20px;
      }
      button:nth-child(2) {
        width: 120px;
        border: 1px solid #3388ff;
        background: #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #fff;
      }
    }
  }
}
.handleDelDialog {
  /deep/.el-dialog {
    width: 450px;
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;
      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
          margin-bottom: 5px;
        }
        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }
    .body {
      height: 70px;
      text-align: center;
      span:nth-child(1) {
        display: inline-block;
        width: 26px;
        line-height: 26px;
        border-radius: 50%;
        background: #ffba00;
        text-align: center;
        color: #fff;
        margin-right: 10px;
        vertical-align: middle;
      }
      span:nth-child(2) {
        display: inline-block;
        vertical-align: middle;
        color: #666666;
        @include add-size1($font_size_18);
      }
    }
    .btn {
      text-align: center;
      button:nth-child(1) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #3388ff;
        margin-right: 20px;
      }
      button:nth-child(2) {
        width: 180px;
        background: #3388ff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #fff;
        margin-right: 20px;
      }
    }
  }
}
.ConfigurerolesDialog {
  /deep/.el-dialog {
    width: 710px;
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;
      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
          margin-bottom: 5px;
        }
        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }
    p {
      @include add-size($font_size_16);
      color: #666666;
    }
    .el-form {
        .el-form-item {
          .el-form-item__label {
            @include add-size($font_size_16);
            color: #666666;
          }
          // .el-input__inner {
          // }
          .el-input {
            @include add-size($font_size_16);
            margin-right: 10px;
          }
          .el-textarea__inner {
            @include add-size($font_size_16);
          }
        }
      }
      .el-checkbox {
        @include add-size($font_size_16);
      }
      .el-checkbox__label {
        width: 50px;
        margin-bottom: 10px;
        margin-right: 5px;
        color: #666666;
        @include add-size($font_size_16);
      }
    .add-user-btn {
      width: 180px;
      background: #3388ff;
      border: 1px solid #3388ff;
      height: 50px;
      border-radius: 10px;
      font-size: 20px;
      color: #ffffff;
      margin-left: 40px;
    }
    .btn {
      text-align: center;
      button:nth-child(1) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        //background: #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 20px;
        color: #3388ff;
        margin-right: 20px;
      }
      button:nth-child(2) {
        width: 180px;
        background: #3388ff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 20px;
        color: #fff;
        margin-right: 20px;
      }
      button:nth-child(3) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        background: #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 20px;
        color: #fff;
        margin-right: 20px;
      }
    }
  }
}
.block {
  margin-top: 20px;
}
.content /deep/ .el-link {
  color: #3978E7;
  font-weight: 400;
}
.content /deep/.el-table th {
  @include add-size($font_size_16);
  vertical-align: middle;
}
.content /deep/.el-table__row {
  @include add-size($font_size_16);
  vertical-align: middle;
}
.content {
  ::-webkit-scrollbar {
    width: 8px!important;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    //width: 20px;
    //height: 4px;
    background-color: #1890ff;
    border-radius: 10px;
  }
}
::-webkit-scrollbar {
  width: 8px!important;
  height: 8px;
  background-color: #1890ff;
  border-radius: 10px;

}
</style>
